@font-face {
    font-family: 'Custom';
    src: url('./font/Roboto-Regular.ttf');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'HighlightText';
    src: url('./font/HighlightText.ttf');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
font-family: 'CardText';
src: url('./font/AmericanCaptain-MdEY.otf');
font-weight: normal;
font-style: normal;
font-display: swap;
}


html, body {
    color: white;
    height: 100%;
    min-height: 100%;
    background-color: rgba(0, 0, 0, 0.945);
    margin: 0px;
        padding-top: 0px;
        background-image: url('./Background.png');
        background-repeat: no-repeat;
        background-size: cover;
               height: 100%;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    touch-action: none;
}

.TableBackground {
        margin: 0px;
        padding-top: 0px;
        touch-action: manipulation;
}

.HomePageBackground {
        color: white;
        height: 100%;
        min-height: 100%;
        background-color: rgba(0, 0, 0, 0.945);
        margin: 0px;
            padding-top: 0px;
            background-image: url('./Background.png');
            background-repeat: no-repeat;
            background-size: cover;
                   height: 100%;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        touch-action: none;
}

.BlurIt {
    -webkit-backdrop-filter: blur(12px);
        backdrop-filter: blur(12px);   
}


.TextAwesomeNess {
  text-shadow: -1px 0 1px black, 
             	1px 0 1px black,
             	0 -1px 1px black,
             	0 4px 1px black,
             	-2px 4px 1px black,
             	2px 3px 2px black;
}

img {
  pointer-events: none;  
}


input {
        caret-color: #C0C0C0;
      }
      

input:focus { 
    outline: none !important;
    box-shadow: 0 0 10px #C0C0C0;
    font-size: larger;
}

.OtpBox {
    font-size: 1.2em;
}

.OtpInputStyle {
        background-color: rgba(0, 0, 0, 0.7);
        height: 100%;
        color: white;
        font-size: 100%;
        border-color: #FDA80D;        
        user-select: none;
        -webkit-user-select: none;
}

/***** TRANSITIONS *****/
.ping {
	-webkit-animation: ping 0.9s ease-in-out infinite both;
	        animation: ping 0.9s ease-in-out infinite both;
}

/* ----------------------------------------------
 * Generated by Animista on 2021-7-28 14:6:43
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation ping
 * ----------------------------------------
 */
 @-webkit-keyframes ping {
    0% {
      -webkit-transform: scale(0.2);
              transform: scale(0.2);
      opacity: 0.8;
    }
    80% {
      -webkit-transform: scale(1.2);
              transform: scale(1.2);
      opacity: 0;
    }
    100% {
      -webkit-transform: scale(2.2);
              transform: scale(2.2);
      opacity: 0;
    }
  }
  @keyframes ping {
    0% {
      -webkit-transform: scale(0.2);
              transform: scale(0.2);
      opacity: 0.8;
    }
    80% {
      -webkit-transform: scale(1.2);
              transform: scale(1.2);
      opacity: 0;
    }
    100% {
      -webkit-transform: scale(2.2);
              transform: scale(2.2);
      opacity: 0;
    }
  }

  .scale-in-hor-center {
    -webkit-animation: scale-in-hor-center 0.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
            animation: scale-in-hor-center 0.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  }

  /* ----------------------------------------------
 * Generated by Animista on 2021-8-2 16:29:49
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation scale-in-hor-center
 * ----------------------------------------
 */
@-webkit-keyframes scale-in-hor-center {
  0% {
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    opacity: 1;
  }
}
@keyframes scale-in-hor-center {
  0% {
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    opacity: 1;
  }
}

.scale-in-hor-right {
	-webkit-animation: scale-in-hor-right 0.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: scale-in-hor-right 0.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2021-8-2 16:36:18
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation scale-in-hor-right
 * ----------------------------------------
 */
 @-webkit-keyframes scale-in-hor-right {
  0% {
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    opacity: 1;
  }
}
@keyframes scale-in-hor-right {
  0% {
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    opacity: 1;
  }
}

.pulsate-fwd {
	-webkit-animation: pulsate-fwd 0.9s ease-in-out infinite both;
	        animation: pulsate-fwd 0.9s ease-in-out infinite both;
}


/* ----------------------------------------------
 * Generated by Animista on 2021-8-14 16:4:44
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation pulsate-fwd
 * ----------------------------------------
 */
 @-webkit-keyframes pulsate-fwd {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes pulsate-fwd {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

.swing-in-top-fwd {
	-webkit-animation: swing-in-top-fwd 0.5s cubic-bezier(0.175, 0.885, 0.320, 1.275) both;
	        animation: swing-in-top-fwd 0.5s cubic-bezier(0.175, 0.885, 0.320, 1.275) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2021-9-8 21:16:7
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation swing-in-top-fwd
 * ----------------------------------------
 */
 @-webkit-keyframes swing-in-top-fwd {
  0% {
    -webkit-transform: rotateX(-100deg);
            transform: rotateX(-100deg);
    -webkit-transform-origin: top;
            transform-origin: top;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg);
    -webkit-transform-origin: top;
            transform-origin: top;
    opacity: 1;
  }
}
@keyframes swing-in-top-fwd {
  0% {
    -webkit-transform: rotateX(-100deg);
            transform: rotateX(-100deg);
    -webkit-transform-origin: top;
            transform-origin: top;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg);
    -webkit-transform-origin: top;
            transform-origin: top;
    opacity: 1;
  }
}

.flip-in-ver-right {
	-webkit-animation: flip-in-ver-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: flip-in-ver-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2021-9-14 18:16:50
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation flip-in-ver-right
 * ----------------------------------------
 */
 @-webkit-keyframes flip-in-ver-right {
        0% {
          -webkit-transform: rotateY(-80deg);
                  transform: rotateY(-80deg);
          opacity: 0;
        }
        100% {
          -webkit-transform: rotateY(0);
                  transform: rotateY(0);
          opacity: 1;
        }
      }
      @keyframes flip-in-ver-right {
        0% {
          -webkit-transform: rotateY(-80deg);
                  transform: rotateY(-80deg);
          opacity: 0;
        }
        100% {
          -webkit-transform: rotateY(0);
                  transform: rotateY(0);
          opacity: 1;
        }
      }

 .rotate-center {
	-webkit-animation: rotate-center 0.1s ease-in-out infinite both;
	        animation: rotate-center 0.1s ease-in-out infinite both;
}

/* ----------------------------------------------
 * Generated by Animista on 2021-9-15 12:19:26
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation rotate-center
 * ----------------------------------------
 */
 @-webkit-keyframes rotate-center {
        0% {
          -webkit-transform: rotate(0);
                  transform: rotate(0);
        }
        100% {
          -webkit-transform: rotate(360deg);
                  transform: rotate(360deg);
        }
      }
      @keyframes rotate-center {
        0% {
          -webkit-transform: rotate(0);
                  transform: rotate(0);
        }
        100% {
          -webkit-transform: rotate(360deg);
                  transform: rotate(360deg);
        }
      }
      
.slide-one-to-one {
	-webkit-animation: slide-one-to-one 0.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-one-to-one 0.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
} 

 @-webkit-keyframes slide-one-to-one {
        0% {
          -webkit-transform: translateY(300%) translateX(0%);
                  transform: translateY(300%) translateX(0%);
          opacity: 0;
        }
        100% {
          -webkit-transform: translateY(0) translateX(0);
                  transform: translateY(0) translateX(0);
          opacity: 1;
        }
      }
      @keyframes slide-one-to-one {
        0% {
        -webkit-transform: translateY(300%) translateX(0%);
                transform: translateY(300%) translateX(0%);
        opacity: 0;
        }
        100% {
          -webkit-transform: translateY(0) translateX(0);
                  transform: translateY(0) translateX(0);
          opacity: 1;
        }
      }

.slide-two-to-one {
	-webkit-animation: slide-two-to-one 0.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-two-to-one 0.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
} 

 @-webkit-keyframes slide-two-to-one {
        0% {
          -webkit-transform: translateY(300%) translateX(150%);
                  transform: translateY(300%) translateX(150%);
          opacity: 0;
        }
        100% {
          -webkit-transform: translateY(0) translateX(0);
                  transform: translateY(0) translateX(0);
          opacity: 1;
        }
      }
      @keyframes slide-two-to-one {
        0% {
        -webkit-transform: translateY(300%) translateX(150%);
                transform: translateY(300%) translateX(150%);
        opacity: 0;
        }
        100% {
          -webkit-transform: translateY(0) translateX(0);
                  transform: translateY(0) translateX(0);
          opacity: 1;
        }
      }

.slide-three-to-one {
	-webkit-animation: slide-three-to-one 0.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-three-to-one 0.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
} 

 @-webkit-keyframes slide-three-to-one {
        0% {
          -webkit-transform: translateY(300%) translateX(300%);
                  transform: translateY(300%) translateX(300%);
          opacity: 0;
        }
        100% {
          -webkit-transform: translateY(0) translateX(0);
                  transform: translateY(0) translateX(0);
          opacity: 1;
        }
      }
      @keyframes slide-three-to-one {
        0% {
        -webkit-transform: translateY(300%) translateX(300%);
                transform: translateY(300%) translateX(300%);
        opacity: 0;
        }
        100% {
          -webkit-transform: translateY(0) translateX(0);
                  transform: translateY(0) translateX(0);
          opacity: 1;
        }
      }

.slide-four-to-one {
	-webkit-animation: slide-four-to-one 0.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-four-to-one 0.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
} 

 @-webkit-keyframes slide-four-to-one {
        0% {
          -webkit-transform: translateY(300%) translateX(450%);
                  transform: translateY(300%) translateX(450%);
          opacity: 0;
        }
        100% {
          -webkit-transform: translateY(0) translateX(0);
                  transform: translateY(0) translateX(0);
          opacity: 1;
        }
      }
      @keyframes slide-four-to-one {
        0% {
        -webkit-transform: translateY(300%) translateX(450%);
                transform: translateY(300%) translateX(450%);
        opacity: 0;
        }
        100% {
          -webkit-transform: translateY(0) translateX(0);
                  transform: translateY(0) translateX(0);
          opacity: 1;
        }
      }

.slide-five-to-one {
	-webkit-animation: slide-five-to-one 0.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-five-to-one 0.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
} 

 @-webkit-keyframes slide-five-to-one {
        0% {
          -webkit-transform: translateY(300%) translateX(600%);
                  transform: translateY(300%) translateX(600%);
          opacity: 0;
        }
        100% {
          -webkit-transform: translateY(0) translateX(0);
                  transform: translateY(0) translateX(0);
          opacity: 1;
        }
      }
      @keyframes slide-five-to-one {
        0% {
        -webkit-transform: translateY(300%) translateX(600%);
                transform: translateY(300%) translateX(600%);
        opacity: 0;
        }
        100% {
          -webkit-transform: translateY(0) translateX(0);
                  transform: translateY(0) translateX(0);
          opacity: 1;
        }
      }

.slide-six-to-one {
	-webkit-animation: slide-six-to-one 0.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-six-to-one 0.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
} 

 @-webkit-keyframes slide-six-to-one {
        0% {
          -webkit-transform: translateY(300%) translateX(750%);
                  transform: translateY(300%) translateX(750%);
          opacity: 0;
        }
        100% {
          -webkit-transform: translateY(0) translateX(0);
                  transform: translateY(0) translateX(0);
          opacity: 1;
        }
      }
      @keyframes slide-six-to-one {
        0% {
        -webkit-transform: translateY(300%) translateX(750%);
                transform: translateY(300%) translateX(750%);
        opacity: 0;
        }
        100% {
          -webkit-transform: translateY(0) translateX(0);
                  transform: translateY(0) translateX(0);
          opacity: 1;
        }
      }

.slide-minus-one-to-one {
	-webkit-animation: slide-minus-one-to-one 0.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-minus-one-to-one 0.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
} 

 @-webkit-keyframes slide-minus-one-to-one {
        0% {
          -webkit-transform: translateY(300%) translateX(-150%);
                  transform: translateY(300%) translateX(-150%);
          opacity: 0;
        }
        100% {
          -webkit-transform: translateY(0) translateX(0);
                  transform: translateY(0) translateX(0);
          opacity: 1;
        }
      }
      @keyframes slide-minus-one-to-one {
        0% {
        -webkit-transform: translateY(300%) translateX(-150%);
                transform: translateY(300%) translateX(-150%);
        opacity: 0;
        }
        100% {
          -webkit-transform: translateY(0) translateX(0);
                  transform: translateY(0) translateX(0);
          opacity: 1;
        }
      }

.slide-minus-two-to-one {
	-webkit-animation: slide-minus-two-to-one 0.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-minus-two-to-one 0.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
} 

 @-webkit-keyframes slide-minus-two-to-one {
        0% {
          -webkit-transform: translateY(300%) translateX(-300%);
                  transform: translateY(300%) translateX(-300%);
          opacity: 0;
        }
        100% {
          -webkit-transform: translateY(0) translateX(0);
                  transform: translateY(0) translateX(0);
          opacity: 1;
        }
      }
      @keyframes slide-minus-two-to-one {
        0% {
        -webkit-transform: translateY(300%) translateX(-300%);
                transform: translateY(300%) translateX(-300%);
        opacity: 0;
        }
        100% {
          -webkit-transform: translateY(0) translateX(0);
                  transform: translateY(0) translateX(0);
          opacity: 1;
        }
      }

.slide-minus-three-to-one {
	-webkit-animation: slide-minus-three-to-one 0.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-minus-three-to-one 0.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
} 

 @-webkit-keyframes slide-minus-three-to-one {
        0% {
          -webkit-transform: translateY(300%) translateX(-450%);
                  transform: translateY(300%) translateX(-450%);
          opacity: 0;
        }
        100% {
          -webkit-transform: translateY(0) translateX(0);
                  transform: translateY(0) translateX(0);
          opacity: 1;
        }
      }
      @keyframes slide-minus-three-to-one {
        0% {
        -webkit-transform: translateY(300%) translateX(-450%);
                transform: translateY(300%) translateX(-450%);
        opacity: 0;
        }
        100% {
          -webkit-transform: translateY(0) translateX(0);
                  transform: translateY(0) translateX(0);
          opacity: 1;
        }
      }

.slide-minus-four-to-one {
	-webkit-animation: slide-minus-four-to-one 0.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-minus-four-to-one 0.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
} 

 @-webkit-keyframes slide-minus-four-to-one {
        0% {
          -webkit-transform: translateY(300%) translateX(-600%);
                  transform: translateY(300%) translateX(-600%);
          opacity: 0;
        }
        100% {
          -webkit-transform: translateY(0) translateX(0);
                  transform: translateY(0) translateX(0);
          opacity: 1;
        }
      }
      @keyframes slide-minus-four-to-one {
        0% {
        -webkit-transform: translateY(300%) translateX(-600%);
                transform: translateY(300%) translateX(-600%);
        opacity: 0;
        }
        100% {
          -webkit-transform: translateY(0) translateX(0);
                  transform: translateY(0) translateX(0);
          opacity: 1;
        }
      }

.slide-minus-five-to-one {
	-webkit-animation: slide-minus-five-to-one 0.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-minus-five-to-one 0.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
} 

 @-webkit-keyframes slide-minus-five-to-one {
        0% {
          -webkit-transform: translateY(300%) translateX(-750%);
                  transform: translateY(300%) translateX(-750%);
          opacity: 0;
        }
        100% {
          -webkit-transform: translateY(0) translateX(0);
                  transform: translateY(0) translateX(0);
          opacity: 1;
        }
      }
      @keyframes slide-minus-five-to-one {
        0% {
        -webkit-transform: translateY(300%) translateX(-750%);
                transform: translateY(300%) translateX(-750%);
        opacity: 0;
        }
        100% {
          -webkit-transform: translateY(0) translateX(0);
                  transform: translateY(0) translateX(0);
          opacity: 1;
        }
      }

.seat-two-card-played {
	-webkit-animation: seat-two-card-played 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: seat-two-card-played 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@-webkit-keyframes seat-two-card-played {
  0% {
    -webkit-transform: translateX(-300%);
            transform: translateX(-300%);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}
@keyframes seat-two-card-played {
  0% {
    -webkit-transform: translateX(-300%);
            transform: translateX(-300%);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}

.seat-four-card-played {
	-webkit-animation: seat-four-card-played 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: seat-four-card-played 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

 @-webkit-keyframes seat-four-card-played {
    0% {
      -webkit-transform: translateX(300%);
              transform: translateX(300%);
      opacity: 1;
    }
    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
      opacity: 1;
    }
  }
  @keyframes seat-four-card-played {
    0% {
    -webkit-transform: translateX(300%);
        transform: translateX(300%);
      opacity: 1;
    }
    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
      opacity: 1;
    }
  }


  .seat-three-card-played {
	-webkit-animation: seat-three-card-played 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: seat-three-card-played 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}


@-webkit-keyframes seat-three-card-played {
  0% {
    -webkit-transform: translateY(-300%);
            transform: translateY(-300%);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}
@keyframes seat-three-card-played {
  0% {
    -webkit-transform: translateY(-300%);
            transform: translateY(-300%);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}

.seat-one-card-played {
	-webkit-animation: seat-one-card-played 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: seat-one-card-played 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}


@-webkit-keyframes seat-one-card-played {
    0% {
      -webkit-transform: translateY(300%);
              transform: translateY(300%);
      opacity: 1;
    }
    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      opacity: 1;
    }
  }
  @keyframes seat-one-card-played {
    0% {
      -webkit-transform: translateY(300%);
              transform: translateY(300%);
      opacity: 1;
    }
    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      opacity: 1;
    }
  }




      